import axios from "axios";

const state = {
   gamePrice: null,
   gameStatus: false,
   allSpinRewards: null,
   // gameReward: null,
   errorNotAllowed: null, 
   error: null,
};

const getters = {  
   getGamePrice(state) {  
      return state.gamePrice;  
   },  
   getAllSpinRewards(state) {  
      return state.allSpinRewards;  
   },  
   getError(state) {  
      return state.error;  
   },  
   // getGameReward(state) {  
   //    return state.gameReward;
   // },  
 
   getErrorNotAllowed(state) {  
      return state.errorNotAllowed ;  
    },
};
const mutations = {
   setGamePrice(state, price) {
      state.gamePrice = price;
   },
   setAllSpinRewards(state, Rewards) {
      state.allSpinRewards = Rewards;
   },
   // setGameReward(state, GameReward) {
   //    state.GameReward = GameReward;
   // },
   setError(state, error) {
      state.error = error;
   },
   setErrorNotAllowed(state, message) {  
      state.errorNotAllowed = message;  
    },
};

const actions = {
   async createSpinGame({ commit }) {
      commit("setGameStatus", true);
      commit("setError", null);
      commit("setErrorNotAllowed", false);

      try {
         const response = await axios.get("/customer/games/spin/create");

         commit("setGamePrice", response.data.data.gamePrice);
         commit("setAllSpinRewards", response.data.data.allSpinRewards);

         commit("setGameStatus", false);
      } catch (error) {  
         const errorMessage = error.response.data.message ;
         commit("setErrorNotAllowed", errorMessage);
       }  
   },

   
   // async startGameSpin({ commit }) {
   //    commit("setGameStatus", true);
   //    commit("setError", null);

   //    try {
   //       const response = await axios.post("/customer/games/spin");
   //       console.log(response , "spin action : ")
   //       commit("setGameReward", response.data.data.gamificationResponse
   //       .reward);
         

   //    } catch (error) {
   //       commit("setError", error.response ? error.response.data : 'خطا در ارتباط با سرور');
   //    }
   // }
};
export default {
   state,
   getters,
   mutations,
   actions
};