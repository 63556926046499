import compare from './modules/compare'
import blog from './modules/blog'
import product from './modules/product'
import home from './modules/home'
import user from './modules/user'
import page from './modules/page'
import faq from './modules/faq'
import panel from './modules/panel'
import area from './modules/area'
import cartStore from './modules/cartStore'
import SpinGame from './modules/SpinGame'
import club from './modules/club'
import state from './state';
import actions from './actions';
import mutations from './mutations'
export default {
    namespaced: true,
    modules: [blog,SpinGame, home, product, user, faq, area, page, panel, compare,cartStore,club],
    state,
    actions,
    mutations,
}
