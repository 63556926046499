import axios from "axios";
import { getCookie } from "../../../../libraries/VueMethods";

const state = {
  extraShippingWithAddItem: {},
  discountCoupen: null,
  changeCartInfo: false,
  hasExtraWeight: false,
  shippings: [],
  cartHasFreeShippingItem: false,
};

const getters = {
  getCartHasFreeShippingItem(state) {
    return state.cartHasFreeShippingItem;
  },
  getShippings(state) {
    return state.shippings;
  },
  getHasExtraWeight(state) {
    return state.hasExtraWeight;
  },
  getChangeCartInfo(state) {
    return state.changeCartInfo;
  },
  getDiscountCoupen(state) {
    return state.discountCoupen;
  },
  getExtraShippingWithAddItem(state) {
    return state.extraShippingWithAddItem;
  },
};

const mutations = {
  setCartHasFreeShippingItem(state, status) {
    state.cartHasFreeShippingItem = status;
  },
  setShippins(state, list) {
    state.shippings = list;
  },
  setHasExtraWeight(state, status) {
    state.hasExtraWeight = status;
  },
  setChangeCartInfo(state, status) {
    state.changeCartInfo = status;
  },
  setDiscountCoupen(state, discount) {
    state.discountCoupen = discount;
  },
  setExtraShippingWithAddItem(state, data) {
    state.extraShippingWithAddItem[data.address_id] = data.shipping_price;
  },
};

const actions = {
  getShippingsFromServer({ commit, state }) {
    if (getCookie("Authorization"))
      axios
        .get('customer/cart?show_warning=1&widgets=["shippings", "gateways"]')
        .then((response) => {
          commit("setShippins", response.data.data.widgets.shippings);
          commit(
            "setCartHasFreeShippingItem",
            response.data.data.carts_showcase.has_free_shipping
          );
        });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
