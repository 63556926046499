<template>
  <div id="app" :class="{'bgWheel': $route.path === '/wheel-of-fortune'}">  
    <MediaQueryProvider :queries="$options.queries">
      <MatchMedia v-slot="{ mobileSize }">
        <notifications
          group="error"
          :position="!mobileSize ? 'top right' : 'top center'"
        />
        <notifications
          group="success"
          :position="!mobileSize ? 'top right' : 'top center'"
        />
        <notifications group="warning" position="top center" />
      </MatchMedia>
      <router-view> </router-view>
      <div v-if="showPwa" class="blurInPwa"></div>
      <div v-if="showPwa" id="installPwa">
        <span id="closePwa" @click="removeInstallPwa()">x</span>
        <img src="./assets/images/pwa/icon.png" alt="" />
        <p>
          <strong>نصب وب اپلیکیشن بندیتو</strong>
          <span>دسترسی آسان به بندیتو از صفحه اصلی</span>
        </p>
        <button @click="installPwa()">نصب</button>
      </div>
      <!-- <div v-show="notificationsModal" id="backBlack">
        <div id="notificationsModal">
          <div id="headerModal">
            <img src="@/assets/images/logo.png" alt="logo" />
            <p>
              آیا دوس دارید که پیغام های ما را دریافت کنید؟
            </p>
          </div>
          <div id="modalButtons">
            <button @click="handlerNotificationButton(true)">همیشه</button>
            <button @click="handlerNotificationButton(false)">فعلا نه</button>
          </div>
        </div>
      </div> -->
      <transition name="fade">
        <div class="loading-spinner" v-if="$store.state.loading">
          <div class="loading-spinner-div" style="margin-top:auto;height:100%">
            <fulfilling-bouncing-circle-spinner
              :animation-duration="4000"
              :size="60"
              :color="colorLoading"
            />
          </div>
        </div>
      </transition>
    </MediaQueryProvider>
  </div>
</template>
<script>
// افزودن کامپوننت زیر برای  حالت موبایلی و استفاده از آن در کامپوننت های دیگر
import { MediaQueryProvider } from "vue-component-media-queries";
import { MatchMedia } from "vue-component-media-queries";
import config from "@/config";
import { getCookie, setCookie } from "@/libraries/VueMethods";

const queries = {
  mobileSize: "(max-width: 768px)",
  xxSmall: "(max-width: 420px)",
  xSmall: "(max-width: 576px)",
  small: "(min-width: 576px)",
  medium: "(max-width: 768px)",
  large: "(max-width: 992px)",
  slarge: "(max-width: 1040px)",
  xLarge: "(min-width: 992px)",
  xxLarge: "(min-width: 1200px)",
  xxsLarge: "(min-width: 1290px)",
  xxxLarge: "(min-width: 1399px)",
};
export default {
  queries,
  components: {
    MediaQueryProvider,
    MatchMedia,
  },
  created() {
    // this.generateNotfication();
    // setTimeout(() => {
    //   if (!getCookie("hideNotificationModal")) {
    //     this.notificationsModal = true;
    //     document
    //       .getElementById("backBlack")
    //       .addEventListener("click", this.handlerBackBlackClick);
    //   }
    // }, 3000);
    if (getCookie("hidePwaBox")||this.$route.fullPath.includes('question')) {
      this.showPwa = false;
    } else {
      if (window.innerWidth < 768) {
        window.addEventListener("beforeinstallprompt", (e) => {
          e.preventDefault();
          // Stash the event so it can be triggered later.
          this.deferredPrompt = e;
          this.showPwa = true;
        });
        window.addEventListener("appinstalled", () => {
          this.deferredPrompt = null;
        });
      }
    }
  },
  methods: {
    // handlerBackBlackClick(e) {
    //   if (e.target.id == "backBlack") {
    //     this.notificationsModal = false;
    //   }
    // },
    // handlerNotificationButton(status) {
    //   if (!status) {
    //     setCookie("hideNotificationModal", true, 3);
    //   }
    //   this.notificationsModal = status;
    //   if (status) {
    //     Notification.requestPermission().then((result) => {
    //       if (result == "denied") {
    //         setCookie("hideNotificationModal", true, 30);
    //       }
    //       if (result === "granted") {
    //         navigator.serviceWorker
    //           .register("service-worker.js")
    //           .then((registration) => {
    //             return registration.pushManager
    //               .getSubscription()
    //               .then(async (subscription) => {
    //                 if (subscription) {
    //                   alert(subscription);
    //                   return subscription;
    //                 }
    //               });
    //           })
    //           .then((subscription) => {
    //             // subscription part
    //           });
    //       }
    //     });
    //   }
    // },

    async installPwa() {
      this.showPwa = false;
      setCookie("hidePwaBox", true, 30);
      this.deferredPrompt.prompt();
    },
    removeInstallPwa() {
      this.showPwa = false;

      setCookie("hidePwaBox", true, 30);
    },
  },
  computed: {
    notifications() {
      return this.$store.getters["front/getNotifications"];
    },
    allNotifications() {
      return this.notifications.items.map((item) => {
        return item.getNotification();
      });
    },
  },
  data() {
    return {
      showPwa: false,
      deferredPrompt: null,
      // notificationsModal: false,
      colorLoading: config.colorLoading,
    };
  },
  
    watch:{
    $route (to, from){
      if(this.$route.name=='ClubDetail'){
          
            document.body.setAttribute("class","ClubDetail-body")
        }else{
          document.body.removeAttribute("class","ClubDetail-body")

        }
    }
}
};
</script>
<style>
/*.bgWheel{
  background-image : url('./assets/images/wheel/W.gif');
}

@media screen and (max-width : 768px){
  .bgWheel{
    background-image : url('./assets/images/wheel/H.gif');
  }
}*/

div#notificationsModal #headerModal {
  padding: 6px 10px;
}
div#notificationsModal #headerModal img {
  width: 120px;
  margin: 24px 0;
}
div#notificationsModal {
  position: fixed;
  right: 50%;
  top: 50%;
  z-index: 100;
  background: white;
  box-shadow: 0 0 5px;
  transform: translate(50%, -50%);
  width: max-content;
}

div#notificationsModal #modalButtons button:last-child {
  background-color: #8080803d;
  color: black;
}
div#notificationsModal #modalButtons button:first-child {
  background-color: var(--color-themepinky);
  color: white;
}
div#notificationsModal #modalButtons button {
  width: 50%;
  padding: 4px;
}
div#installPwa {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100000010000;
  background: white;
  padding: 6px 10px 52px;
  display: none;
  grid-template-columns: 32px 40px 1fr 32px;
  align-items: center;
  gap: 8px;
  right: 0;
  border-radius: 28px 28px 0 0;
  border: none;
}
@media (max-width: 768px) {
  div#installPwa {
    display: grid !important;
  }
}
span#closePwa {
  align-self: flex-start;
  border: none;
  height: 30px;
  line-height: 31px;
  border-radius: 4px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}
div#installPwa img {
  width: 100%;
  border: 1px solid #1213;
  box-shadow: 0 0 2px #1213;
  border-radius: 4px;
  padding: 4px;
}
div#installPwa p {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  margin: 0;
}
div#installPwa button {
  border: none;
  color: #ec6ea5;
  background-color: transparent;
  font-weight: bold;
}
div#installPwa p span {
  opacity: 0.5;
}
.blurInPwa {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #00000070;
  z-index: 9999999999;
}
#notificationsBtn {
  opacity: 0;
  position: fixed;
  left: -200px;
  z-index: -1;
}
div#backBlack {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 11110;
  background: #0000008f;
}
</style>
